import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function IdeationImages({ images = [] }) {
  return (
    <div className="sketches">
      {images.map((img) => (
        <GatsbyImage
          key={img.id}
          image={img.gatsbyImageData}
          alt={img.title}
          className="fadeInUp sketch_img"
        />
      ))}
    </div>
  )
}
